'use client';

import React, { createContext, useContext } from 'react';

const ForceBreakpointContext = createContext('none');

export const ForceBreakpointProvider = ({
  children,
  bp,
}: {
  children: React.ReactNode;
  bp: 'mobile' | 'tablet' | 'desktop' | 'none';
}) => (
  <ForceBreakpointContext.Provider value={bp}>
    {children}
  </ForceBreakpointContext.Provider>
);

export const useForceBreakpoint = () => useContext(ForceBreakpointContext);
