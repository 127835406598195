import { styleChildLinks } from '../shared/helpers';
import {
  ButtonOrLink,
  CloseButton,
  CloseButtonIcon,
  ContentContainer,
  IconWrapper,
  typeMappings,
} from '../shared/styles';
import {
  InlineMessageContainer,
  MessageText,
  TitleText,
} from './InlineMessage.styles';
import { InlineMessageProps } from './types';
import { toKebabCase } from '@midwest/web/base';

/**
 ```jsx
 import { MDSInlineMessage } from '@midwest/web/notifications-v2';
 ``` 
 * Inline messages are used to provide notifications, give feedback for an action, or call out important information for the user. They usually appear at the top of the primary content area or close to the item needing attention. 
 */
export const MDSInlineMessage = ({
  title,
  closeButton,
  closeButtonId,
  closeOnClick,
  children,
  type = 'info',
  subtle = false,
  buttonOrLink,
}: InlineMessageProps) => {
  const darkBg = !subtle && (type === 'info' || type === 'negative');
  const Icon = typeMappings[type].icon;
  const childrenWithLinkProps = styleChildLinks(children, darkBg, type, subtle);

  return (
    <InlineMessageContainer
      $type={type}
      $subtle={subtle}
      aria-label={`${typeMappings[type].inlineMessageLabel} notification`}
    >
      <IconWrapper aria-hidden="true" $type={type} $subtle={subtle}>
        <Icon />
      </IconWrapper>
      <ContentContainer role={typeMappings[type].role}>
        {!!title && (
          <TitleText $subtle={subtle} as={'h2'}>
            {title}
          </TitleText>
        )}

        <MessageText $subtle={subtle}>{childrenWithLinkProps}</MessageText>
        {buttonOrLink && (
          <ButtonOrLink
            darkBg={darkBg}
            type={type}
            buttonOrLink={buttonOrLink}
            subtle={subtle}
          />
        )}
      </ContentContainer>
      {!!closeButton && (
        <CloseButton
          aria-label="Close"
          data-testid="system-alert-close-button"
          onClick={closeOnClick}
          darkBg={darkBg}
          data-analytics-id={closeButtonId ? toKebabCase(closeButtonId) : null}
        >
          <CloseButtonIcon $type={type} $subtle={subtle} />
        </CloseButton>
      )}
    </InlineMessageContainer>
  );
};
