import { ImageProps } from 'next/image';
import React from 'react';

export interface VideoProps {
  videoId: string;
  image?: ImageProps;
  className?: string;
  titleText?: string;
  captionText?: string;
  linkComponent?: React.ReactNode;
  variant?: boolean;
  dotcomButton?: 'sm' | 'lg';
  brandRefresh?: boolean;
  onInitializeVideo?: () => void;
}

type VideoWidgetParams = {
  selector: string;
  type:
    | 'playback'
    | 'vertical'
    | 'thumbnail'
    | 'featured'
    | 'carousel'
    | 'grid'
    | 'playlist';
  guid: string;
  info?: object;
  autoLoad: boolean;
  autoPlay: boolean;
  loop: boolean;
  closeOverlayOnFinish: boolean;
  showInfoPanel: boolean;
  hideEmbedButton: boolean;
  hideDownloadButton: boolean;
  hideShareButton: boolean;
  player?: {
    maxWidth?: number;
  };
};
export function isVideoMetadata(
  data?: VideoMetadata | number,
): data is VideoMetadata {
  return typeof data === 'object' && !!data?.title;
}

export interface WidgetApiMethods {
  bind: (
    event: 'play' | 'ended' | 'volumechange' | 'load' | 'pause',
    callback: (param?: VideoMetadata | number) => void,
  ) => void;
  get: (
    propertyName: 'currentTime' | 'paused',
    callback: (value?: number | boolean) => void,
  ) => void;
  command: (
    propertyName: 'destroy' | 'play',
    params?: [],
    callback?: () => void,
  ) => void;
}

export interface WidgetInstance {
  api?: WidgetApiMethods;
  getSdk?: object;
}

export interface WidgetObject {
  (params: VideoWidgetParams): WidgetInstance | undefined;
  widgets: WidgetInstance[];
}
export interface VideoWindow extends Window {
  KV?: {
    widget: WidgetObject;
  };
}

export interface VideoMetadata {
  guid: string;
  revisionGuid: string;
  title: string;
  passwordProtected: boolean;
  createdInApp: boolean;
  allowFeedback: boolean;
  allowQuestions: boolean;
  allowUserSelectedSlides: boolean;
  allowComments: boolean;
  allowLikes: boolean;
  allowLiveComments: boolean;
  requiresAccessToken: boolean;
  requiresRegistration: boolean;
  publisher: Publisher;
  duration: number;
  audioOnly: boolean;
  hasAudio: boolean;
  totalDownloadSize: number;
  player: string;
  distributionRecordStatus: string;
  distributionPolicyRequired: boolean;
  infoTabEnabled: boolean;
  shareTabEnabled: boolean;
  speechSearchTabEnabled: boolean;
  overlayType: string;
  defaultPlayerView: string;
  allowTranscription: boolean;
  displayAutoGeneratedCaptions: boolean;
  created: string;
  modified: string;
  published: string;
  public: boolean;
  downloadable: boolean;
  appOfflineViewing: boolean;
  type: Type;
  state: string;
  vod: boolean;
  sourceMedia?: SourceMediaEntity[] | null;
  thumbnail: Thumbnail;
  captionTracks?: CaptionTracksEntity[] | null;
  metadata?: MetadataEntity[] | null;
  groups?: GroupsEntity[] | null;
  mediaDisplayWidth: number;
  mediaDisplayHeight: number;
  media: Media;
  templates: object;
}
interface Publisher {
  name: string;
  telephone: string;
}
interface Type {
  guid: string;
  title: string;
}
interface SourceMediaEntity {
  guid: string;
  duration: number;
  source: Source;
  created: string;
  modified: string;
}
interface Source {
  sourceType: string;
}
interface Thumbnail {
  guid: string;
  height: number;
  width: number;
  url: string;
  projection: string;
  autoGenerated: boolean;
  cdnUrl: string;
}
interface CaptionTracksEntity {
  guid: string;
  title: string;
  languageCode: string;
  source: string;
  created: number;
  modified: number;
}
interface MetadataEntity {
  guid: string;
  value?: string | ValueEntity[] | null;
  type: string;
  html?: string | null;
  title: string;
}
interface ValueEntity {
  guid: string;
  value: string;
}
interface GroupsEntity {
  guid: string;
  name: string;
}
interface Media {
  variants?: VariantsEntity[] | null;
  projection: string;
  url: string;
}
interface VariantsEntity {
  url: string;
  formatCode: string;
  size: number;
  width?: number | null;
  height?: number | null;
}

export const isNumber = (
  value: number | boolean | undefined,
): value is number => typeof value === 'number';
