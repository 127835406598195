'use client';

import { BaseLink } from '../BaseLink';
import { StyledLogo } from '../Logo';
import { CloseIcon } from '../svg';
import {
  Backdrop,
  Menu,
  MenuBody,
  MenuFooter,
  MenuHeader,
  NavIcon,
  NavListItem,
} from './BasicWhiteBackgroundHeaderMobileMenu.style';
import { HeaderLoginButton } from './HeaderLoginButton';
import { useBreakpoint } from '@exploration/ui-utils';
import { MDSAnchorButton } from '@midwest/web/button';
import { Menu_Hamburger as MenuHamburger } from '@thrivent/midwest-web';
import { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

export const BasicWhiteBackgroundHeaderMobileMenu = ({
  brandRefresh,
}: {
  brandRefresh?: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [requestClose, setRequestClose] = useState(false);
  const bp = useBreakpoint();
  const { midwestColors: colors } = useTheme();

  useEffect(() => {
    if (menuOpen) {
      if (ref.current) {
        ref.current.classList.remove('animate__slideOutRight');
        ref.current.classList.add('animate__slideInRight');
      }
      document.body.setAttribute('data-scroll-locked', 'true');
    } else {
      document.body.setAttribute('data-scroll-locked', 'false');
    }

    return () => {
      document.body.setAttribute('data-scroll-locked', 'false');
    };
  }, [menuOpen]);

  useEffect(() => {
    if (requestClose) {
      if (ref.current) {
        ref.current.classList.remove('animate__slideInRight');
        document.body.setAttribute('data-scroll-locked', 'false');

        ref.current.classList.add('animate__slideOutRight');
        document.body.setAttribute('data-scroll-locked', 'true');
        ref.current.addEventListener(
          'animationend',
          () => {
            setMenuOpen(false);
          },
          { once: true },
        );
      } else {
        setMenuOpen(false);
      }

      setRequestClose(false);
    }
  }, [requestClose]);

  useEffect(() => {
    if (bp !== 'mobile' && bp !== 'tablet') {
      setMenuOpen(false);
      document.body.setAttribute('data-scroll-locked', 'false');
    }
  }, [bp]);

  function open() {
    document.body.classList.add('header-is-open');
    setMenuOpen(!menuOpen);
  }

  function close() {
    document.body.classList.remove('header-is-open');
    setRequestClose(true);
  }

  return (
    <>
      <NavIcon
        onClick={open}
        data-analytics-id="mobile-nav-menu-open"
        aria-label="Menu Open"
      >
        <MenuHamburger fill={colors.neutralDarkHeavy} />
      </NavIcon>

      {menuOpen && (
        <>
          <Backdrop onClick={close} />
          <Menu
            ref={ref}
            className="animate__animated animate__faster"
            role="dialog"
            data-analytics-name="mobile-nav-menu"
            brandRefresh={brandRefresh}
          >
            <MenuHeader>
              <BaseLink href="/" onClick={close} data-analytics-id="home">
                <StyledLogo />
              </BaseLink>
              <NavIcon
                onClick={close}
                data-analytics-id="mobile-nav-menu-close"
                aria-label="Menu Close"
              >
                <CloseIcon color="#141414" />
              </NavIcon>
            </MenuHeader>
            <MenuBody>
              <NavListItem
                href="/why-thrivent"
                onClick={close}
                data-analytics-id="why-thrivent"
              >
                Why Thrivent
              </NavListItem>
              <NavListItem
                href="/product-overview"
                onClick={close}
                data-analytics-id="products"
              >
                Products & services
              </NavListItem>
              <NavListItem
                href="/working-with-a-financial-advisor"
                onClick={close}
                data-analytics-id="working-with-a-financial-advisor"
              >
                Working with a financial advisor
              </NavListItem>
              <NavListItem
                href="/giving-back"
                onClick={close}
                data-analytics-id="giving-back"
              >
                Giving back
              </NavListItem>
            </MenuBody>
            <MenuFooter>
              <HeaderLoginButton fullWidth />
              <MDSAnchorButton
                fullWidth
                variant="primary"
                href="https://local.thrivent.com"
                data-analytics-id="find-financial-advisors"
              >
                Find financial advisors
              </MDSAnchorButton>
            </MenuFooter>
          </Menu>
        </>
      )}
    </>
  );
};
