'use client';

import {
  OPT_IN_BANNER_DISMISSAL_COOKIE_AGE,
  OPT_IN_BANNER_DISMISSAL_COOKIE_NAME,
} from './constants';
import { Container as ExplorationContainer } from '@exploration/shared-components';
import { MDSText } from '@midwest/web/base';
import { MDSLink } from '@midwest/web/link';
import { MDSSystemAlert } from '@midwest/web/notifications';
import { removeCookies, setCookie } from 'cookies-next';
import { useState } from 'react';
import styled from 'styled-components';

// --------------------------------------------------------------------------

const Message = styled(MDSSystemAlert)`
  color: ${(p) => p.theme.midwestColors.white};
  box-shadow: none;
  padding-inline: 0;
`;
const Link = styled(MDSLink)`
  color: ${(p) => p.theme.midwestColors.white};
  text-decoration: underline;

  &:hover,
  &:focus {
    color: ${(p) => p.theme.midwestColors.white};
    text-decoration: none;
    font-weight: 500;
  }

  &:visited {
    color: ${(p) => p.theme.midwestColors.white};
  }
`;

const Banner = styled.section`
  background-color: ${(p) =>
    p.theme.midwestColors.semanticColorSentimentInfoMedium};
`;
const Container = styled(ExplorationContainer)`
  background-color: ${(p) =>
    p.theme.midwestColors.semanticColorSentimentInfoMedium};
`;

// --------------------------------------------------------------------------

export const DotcomOptInBanner = () => {
  const [dismissed, setDismissed] = useState(false);
  const clickHandler = () => {
    setCookie(OPT_IN_BANNER_DISMISSAL_COOKIE_NAME, true, {
      maxAge: OPT_IN_BANNER_DISMISSAL_COOKIE_AGE,
    });
    setDismissed(true);
  };

  return (
    <Banner>
      {!dismissed && (
        <Container>
          <Message type="informative" closeButton closeOnClick={clickHandler}>
            <Container>
              <MDSText.BodyHeavyDefault>
                Same website, new look
              </MDSText.BodyHeavyDefault>
              <MDSText.BodyRegDefault as="p">
                We are in the process of bringing you a new Thrivent.com
                experience.
              </MDSText.BodyRegDefault>
              <Link
                data-analytics-id="dotcom-opt-in-banner-cta"
                darkBg={true}
                href="/"
                onClick={() => {
                  removeCookies('dotcom-opt-out');
                }}
              >
                Click here to go to our new site
              </Link>
            </Container>
          </Message>
        </Container>
      )}
    </Banner>
  );
};
