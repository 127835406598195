import { MDSText } from '@midwest/web/base';
import { MDSAnchorButton } from '@midwest/web/button';
import { MDSLink } from '@midwest/web/link';
import { tablet } from '@thrivent/midwest-shared';
import styled from 'styled-components';

export const IconCardStyles = {
  Container: styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1.5rem;

    ${tablet} {
      justify-content: space-between;
    }
  `,
  Content: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,
  ContentContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,
  IconContainer: styled.div`
    height: 3.625rem;
    width: 3.625rem;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      max-width: 100%;
      max-height: 100%;
    }
  `,
  Subtitle: styled(MDSText.TitleSm)``,
  Description: styled(MDSText.BodyRegSm)`
    > p:not(:first-child) {
      padding-top: 0.5rem;
    }
    > ul {
      padding-left: 1rem;
    }
  `,
  Button: styled(MDSAnchorButton)<{
    forceMobileBreakpoint?: boolean;
    darkBlueColor?: boolean;
  }>`
    ${({ darkBlueColor, theme }) => `
      ${
        darkBlueColor &&
        `
        background-color: ${theme.midwestColors.componentButtonColorBackgroundStrongPrimaryDefault};
        border-color: ${theme.midwestColors.componentButtonColorBackgroundStrongPrimaryDefault};
      `
      }
    `}
    ${({ forceMobileBreakpoint }) => `
      margin-top: 0.25rem;
      text-decoration: none;
      width: 100%;

      ${
        !forceMobileBreakpoint &&
        `
        ${tablet} {
          width: fit-content;
        }
      `
      }
    `}
  `,
  Link: styled(MDSLink)``,
};
