'use client';

import DefaultImage from './image-default';
import {
  CloseButton,
  DialogContent,
  DialogTitle,
} from '@thrivent-web/ui/atoms';
import { desktop, tablet, textBodyRegDefault } from '@thrivent/midwest-shared';
import { MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

const Image = styled(DefaultImage)`
  z-index: 1;
  position: relative;
  object-fit: cover;
  width: 100%;
  ${desktop} {
    height: 100%;
  }
`;

const ImageContainer = styled.div`
  height: 100%;
`;

// --------------------------------------------------------------------------
export const VideoStyles = {
  VideoContainer: styled.div<{
    linkDisplay?: boolean;
  }>`
    width: 100%;
    position: relative;
    cursor: pointer;
    background: ${(p) =>
      p.linkDisplay ? 'inherit' : p.theme.midwestColors.neutralDarkHeavy};
  `,
  ImageContainer,
  Image,

  Button: styled.button`
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    border: none;
    color: white;
    padding: 0;
    background: none;
  `,
  Card: styled.div`
    display: grid;
    grid-template-columns: 3.25rem 1fr;
    text-align: left;

    ${tablet} {
      grid-template-columns: 5rem 1fr;
    }

    ${desktop} {
      grid-template-columns: 6.25rem 1fr;
    }
  `,
  LoaderWrapper: styled.div`
    div[title='spinner'] {
      width: 200px;
    }
  `,
  PlayButton: styled.span`
    width: 3.25rem;
    height: 3.25rem;
    background-color: black;
    grid-row: 1 / 3;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 40%;
    }

    ${tablet} {
      width: 5rem;
      height: 5rem;
    }

    ${desktop} {
      width: 6.25rem;
      height: 6.25rem;
    }
  `,
  CardTitle: styled(MDSText.BodyHeavySm)`
    padding-left: 1rem;
    margin: 0;
    align-self: end;

    ${tablet} {
      font-size: ${textBodyRegDefault.rem}rem;
    }
  `,
  CardCaption: styled(MDSText.BodyRegSm)`
    padding-left: 1rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    align-self: start;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    ${tablet} {
      font-size: ${textBodyRegDefault.rem}rem;
    }
  `,
  Link: styled.span`
    padding: 0;
    margin: 0;
    display: flex;
    color: ${(p) => p.theme.midwestColors.componentLinkDefault};
    &:hover,
    &:focus {
      color: ${(p) => p.theme.midwestColors.componentLinkHover};
      cursor: pointer;
    }
  `,
  CircularPlayButton: styled.span<{
    variant?: boolean;
  }>`
    width: 1.5rem;
    height: 1.5rem;
    background-color: ${(p) =>
      p.variant
        ? p.theme.midwestColors.componentLinkDefault
        : p.theme.midwestColors.componentLinkHover};
    grid-row: 1 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 0.5rem;

    > svg {
      width: 35%;
      margin-left: 3px;
    }
  `,
  VideoButtonContainer: styled.button`
    appearance: none;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
  `,
  IconGroup: styled.span<{
    variant?: boolean;
  }>`
    display: flex;
    align-items: center;
    font-weight: ${(p) => (p.variant ? textBodyRegDefault.weight : 600)};
    font-size: ${(p) => (p.variant ? '1rem' : 'inherit')};
  `,
};

export const DialogStyles = {
  DialogContent: styled(DialogContent)`
    min-height: 400px;
    background-color: ${(p) =>
      p.theme.midwestColors.neutralDarkHeavy} !important;
    z-index: 600;
    overflow: hidden !important;
  `,
  DialogTitle: styled(DialogTitle)`
    display: none;
  `,
  CloseButton: styled(CloseButton)`
    margin-left: auto;
  `,
};

export const DotcomButtonStyles = {
  ButtonOutline: styled.button`
    border-radius: 100px;
    display: flex;
    background: #fff;
    box-shadow: 0.25rem 0.25rem 1.5rem 1rem rgba(0, 0, 0, 0.05);
    width: auto;
    min-width: 18.25rem;
    height: 5.75rem;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    border: none;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: -2.5rem;
    overflow: visible;

    ${tablet} {
      bottom: 0;
      left: 0;
      margin-bottom: 1.5rem;
      margin-left: 1rem;
      transform: none;
    }
  `,
  DotcomPlayButton: styled.span<{ brandRefresh: boolean }>`
    display: flex;
    border-radius: 62.5rem;
    background: ${(p) =>
      p.brandRefresh
        ? p.theme.midwestColors.identityBrandPrimaryWeak
        : p.theme.midwestColors.backgroundInfoSubtle};
    width: 3.5rem;
    height: 3.5rem;
    align-items: center;
    justify-content: center;
    margin-left: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    > svg {
      width: 35%;
      margin-left: 3px;
    }
  `,
  DotcomText: styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    padding-inline: 1rem;
    text-align: left;
    white-space: nowrap;
  `,
  DotcomTitle: styled(MDSText.BodyHeavySm)`
    font-size: 1rem;
  `,
  DotcomCaption: styled(MDSText.BodyRegSm)`
    padding-top: 0.25rem;
  `,
};

export const DotcomButtonStylesSmall = {
  SmallButtonContainer: styled.div`
    ${DotcomButtonStyles.ButtonOutline} {
      width: auto;
      min-width: auto;
      height: auto;
      display: flex;
      gap: 0.5rem;
      padding: 0.5rem 1rem 0.5rem 0.75rem;

      left: 0;
      transform: none;
      bottom: 0;
      margin-bottom: 1.5rem;
      margin-left: 1rem;
      align-items: center;
    }

    ${DotcomButtonStyles.DotcomText} {
      padding: 0;
    }
    ${DotcomButtonStyles.DotcomPlayButton} {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0;
    }
  `,
};
