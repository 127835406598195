'use client';

import BarChart from './svg/bar-chart';
import BankBuilding from './svg/brand-refresh/bank-building';
import BarGraphUp from './svg/brand-refresh/bar-graph-up';
import BeachChair from './svg/brand-refresh/beach-chair';
import BrandRefreshShield from './svg/brand-refresh/brand-refresh-shield';
import CheckList from './svg/brand-refresh/check-list';
import HandGiftIcon from './svg/brand-refresh/hand-gift-icon';
import HandWithPlant from './svg/brand-refresh/hand-with-plant';
import MapPoint from './svg/brand-refresh/map-point';
import PeopleWithHeart from './svg/brand-refresh/people-with-heart';
import PersonIcon from './svg/brand-refresh/person-icon';
import PersonInComputer from './svg/brand-refresh/person-in-computer';
import PersonOnLaptop from './svg/brand-refresh/person-on-laptop';
import PieChartBrandRefresh from './svg/brand-refresh/pie-chart';
import PiggyBankIcon from './svg/brand-refresh/piggy-bank';
import ThriventLogo from './svg/brand-refresh/thrivent-logo';
import BullseyeWithArrow from './svg/bullseye-with-arrow';
import ArrowLeft from './svg/button-icons/arrow-left';
import ArrowRight from './svg/button-icons/arrow-right';
import Cross from './svg/button-icons/cross';
import Play from './svg/button-icons/play';
import Devices from './svg/devices';
import DottedPath from './svg/dotted-path';
import HandPlant from './svg/hand-plant';
import HandShake from './svg/hand-shake';
import ManOnLaptop from './svg/man-on-laptop';
import AlarmClock from './svg/multi-tone/alarm-clock';
import HeartHand from './svg/multi-tone/heart-hand';
import Shield from './svg/multi-tone/shield';
import WateringCan from './svg/multi-tone/watering-can';
import PeopleChatting from './svg/people-chatting';
import PieChart from './svg/pie-chart';
import Pig from './svg/pig';
import PinOnMap from './svg/pin-on-map';
import PuzzlePieces from './svg/puzzle-pieces';
import SheetCalculator from './svg/sheet-calculator';
import SquareAccentCurrencyCircle from './svg/square-accent/square-accent-currency-circle';
import SquareAccentCurrencyHands from './svg/square-accent/square-accent-currency-hands';
import SquareAccentShieldCheck from './svg/square-accent/square-accent-shield-check';
import SupportPerson from './svg/support-person';
import Trust from './svg/trust';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export enum svgIcons {
  pinOnMap = 'svgPinOnMap',
  manOnLaptop = 'svgManOnLaptop',
  handPlant = 'handPlant',
  barChart = 'barChart',
  pieChart = 'pieChart',
  trust = 'trust',
  pig = 'pig',
  peopleChatting = 'peopleChatting',
  devices = 'devices',
  sheetCalculator = 'sheetCalculator',
  dottedPath = 'dottedPath',
  puzzlePieces = 'puzzlePieces',
  bullseyeWithArrow = 'bullseyeWithArrow',
  supportPerson = 'supportPerson',
  handShake = 'handShake',

  alarmClock = 'alarmClock',
  heartHand = 'heartHand',
  wateringCan = 'wateringCan',
  shield = 'shield',

  bankBuilding = 'bankBuilding',
  barGraphUp = 'barGraphUp',
  beachChair = 'beachChair',
  brandRefreshShield = 'brandRefreshShield',
  checkList = 'checkList',
  handGiftIcon = 'handGiftIcon',
  handWithPlant = 'handWithPlant',
  mapPoint = 'mapPoint',
  peopleWithHeart = 'peopleWithHeart',
  personInComputer = 'personInComputer',
  personOnLaptop = 'personOnLaptop',
  pieChartBR = 'pieChartBR',
  piggyBankIcon = 'piggyBankIcon',
  thriventLogo = 'thriventLogo',
  personIcon = 'personIcon',

  squareAccentCurrencyCircle = 'squareAccentCurrencyCircle',
  squareAccentCurrencyHands = 'squareAccentCurrencyHands',
  squareAccentShieldCheck = 'squareAccentShieldCheck',

  arrowRight = 'arrowRight',
  arrowLeft = 'arrowLeft',
  cross = 'cross',
  play = 'play',
}

const SvgIcons = {
  // General
  [svgIcons.pinOnMap]: PinOnMap,
  [svgIcons.manOnLaptop]: ManOnLaptop,
  [svgIcons.handPlant]: HandPlant,
  [svgIcons.barChart]: BarChart,
  [svgIcons.pieChart]: PieChart,
  [svgIcons.trust]: Trust,
  [svgIcons.pig]: Pig,
  [svgIcons.peopleChatting]: PeopleChatting,
  [svgIcons.devices]: Devices,
  [svgIcons.sheetCalculator]: SheetCalculator,
  [svgIcons.dottedPath]: DottedPath,
  [svgIcons.puzzlePieces]: PuzzlePieces,
  [svgIcons.bullseyeWithArrow]: BullseyeWithArrow,
  [svgIcons.supportPerson]: SupportPerson,
  [svgIcons.handShake]: HandShake,

  // Mutli Tone
  [svgIcons.alarmClock]: AlarmClock,
  [svgIcons.heartHand]: HeartHand,
  [svgIcons.wateringCan]: WateringCan,
  [svgIcons.shield]: Shield,

  // Brand refresh
  [svgIcons.bankBuilding]: BankBuilding,
  [svgIcons.barGraphUp]: BarGraphUp,
  [svgIcons.beachChair]: BeachChair,
  [svgIcons.brandRefreshShield]: BrandRefreshShield,
  [svgIcons.checkList]: CheckList,
  [svgIcons.handGiftIcon]: HandGiftIcon,
  [svgIcons.handWithPlant]: HandWithPlant,
  [svgIcons.mapPoint]: MapPoint,
  [svgIcons.peopleWithHeart]: PeopleWithHeart,
  [svgIcons.personInComputer]: PersonInComputer,
  [svgIcons.personOnLaptop]: PersonOnLaptop,
  [svgIcons.pieChartBR]: PieChartBrandRefresh,
  [svgIcons.piggyBankIcon]: PiggyBankIcon,
  [svgIcons.personIcon]: PersonIcon,
  [svgIcons.thriventLogo]: ThriventLogo,

  // Square Accent
  [svgIcons.squareAccentCurrencyCircle]: SquareAccentCurrencyCircle,
  [svgIcons.squareAccentCurrencyHands]: SquareAccentCurrencyHands,
  [svgIcons.squareAccentShieldCheck]: SquareAccentShieldCheck,

  // Button Icons
  [svgIcons.arrowRight]: ArrowRight,
  [svgIcons.arrowLeft]: ArrowLeft,
  [svgIcons.cross]: Cross,
  [svgIcons.play]: Play,
};

const IconComponent = ({
  icon,
  height,
  width,
  colorPrimary,
  colorSecondary,
  colorTertiary,
  className,
}: {
  icon?: svgIcons;
  height?: number;
  width?: number;
  colorPrimary?: string;
  colorSecondary?: string;
  colorTertiary?: string;
  className?: string;
}) => {
  const IconToUse = (icon && SvgIcons[icon]) ?? SvgIcons.barChart;
  return (
    <IconToUse
      className={className}
      height={height}
      width={width}
      colorPrimary={colorPrimary}
      colorSecondary={colorSecondary}
      colorTertiary={colorTertiary}
    />
  );
};

// This empty styling allows us to override styles in style blocks.
// i.e:
// const MyStyledComponent = styled(MyComponent)`
//   ${Icon} {
//     background-color: orange;
//   }
// `;
export const Icon = styled(IconComponent)``;
