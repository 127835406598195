import { getFontColors } from '../shared/styles';
import { NotificationTypes } from '../shared/types';
import { MDSText } from '@midwest/web/base';
import { MidwestColors } from '@thrivent/midwest-shared';
import styled, { DefaultTheme, css } from 'styled-components';

export const InlineMessageContainer = styled.section<{
  $type: NotificationTypes;
  $subtle?: boolean;
  theme: DefaultTheme;
}>`
  display: flex;
  padding: ${(p) => p.theme.spacing.containerPaddingMd};
  align-items: flex-start;
  gap: ${(p) => p.theme.spacing.containerPaddingSm};
  align-self: stretch;
  border-radius: ${(p) => p.theme.radius.containerRadius};

  background-color: ${({ $type, theme }) =>
    $type === 'info'
      ? theme.midwestColors.componentContainerColorBackgroundInfoEmphasis
      : $type === 'positive'
        ? theme.midwestColors.componentContainerColorBackgroundPositiveEmphasis
        : $type === 'negative'
          ? theme.midwestColors
              .componentContainerColorBackgroundNegativeEmphasis
          : theme.midwestColors
              .componentContainerColorBackgroundWarningEmphasis};
  color: ${({ $type, theme }) =>
    getFontColors(theme.midwestColors as MidwestColors)[$type]};

  outline: ${(p) => p.theme.border.widthDefault} solid
    ${({ $type, theme }) =>
      ($type === 'positive' &&
        theme.midwestColors.componentContainerColorBorderPositive) ||
      ($type === 'warning' &&
        theme.midwestColors.componentContainerColorBorderWarning) ||
      'transparent'};

  ${(p) =>
    p.$subtle &&
    css<{ $type: NotificationTypes; theme: DefaultTheme }>`
      background-color: ${(p) =>
        p.$type === 'info'
          ? p.theme.midwestColors.componentContainerColorBackgroundInfoSubtle
          : p.$type === 'positive'
            ? p.theme.midwestColors
                .componentContainerColorBackgroundPositiveSubtle
            : p.$type === 'negative'
              ? p.theme.midwestColors
                  .componentContainerColorBackgroundNegativeSubtle
              : p.theme.midwestColors
                  .componentContainerColorBackgroundWarningSubtle};
      outline-color: ${(p) =>
        p.$type === 'info'
          ? p.theme.midwestColors.componentContainerColorBorderInfo
          : p.$type === 'negative' &&
            p.theme.midwestColors.componentContainerColorBorderNegative};
    `}
`;

export const TitleText = styled(MDSText.BodyHeavyDefault)<{ $subtle: boolean }>`
  color: ${({ $subtle, theme }) =>
    $subtle
      ? theme.midwestColors.componentContainerColorTextHeadingSubtle
      : 'inherit'};
`;

export const MessageText = styled(MDSText.BodyRegDefault)<{ subtle: boolean }>`
  color: ${({ $subtle, theme }) =>
    $subtle
      ? theme.midwestColors.componentContainerColorTextParagraphSubtle
      : 'inherit'};
`;
