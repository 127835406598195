import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const SquareAccentCurrencyHands = ({
  height = 50,
  width = 50,
  colorPrimary,
  colorSecondary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryMedium;
  const secondary = colorSecondary ?? midwestColors.identityBrandPrimaryFaint;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.01471 9.05883C5.01471 8.06796 5.81796 7.26471 6.80882 7.26471H32.1029C33.0938 7.26471 33.8971 8.06796 33.8971 9.05883V12.8529H37.8971V9.05883C37.8971 5.85882 35.3029 3.26471 32.1029 3.26471H6.80882C3.60882 3.26471 1.01471 5.85882 1.01471 9.05883V34.3529C1.01471 37.5529 3.60882 40.1471 6.80882 40.1471H10.6029V36.1471H6.80882C5.81796 36.1471 5.01471 35.3438 5.01471 34.3529V9.05883Z"
        fill={secondary}
      />
      <path
        d="M13.6109 38.1943C14.3095 38.1306 15.1281 38.004 15.6099 37.75C16.5733 37.2427 17.8584 35.9742 17.8584 35.9742C17.8584 35.9742 19.1427 34.5003 21.3268 35.3321L29.677 38.2579C29.677 38.2579 31.2185 38.8289 31.0258 39.9707C30.8331 41.1125 28.8418 40.6683 28.8418 40.6683L23.9926 39.6853"
        stroke={primary}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.696 39.3363L38.124 37.4647C38.124 37.4647 39.7937 36.989 40.4037 37.9159C40.4037 37.9159 41.7851 39.2411 38.9585 40.5096L29.0349 44.4115C29.0349 44.4115 28.2642 45.014 26.53 44.2212L17.6655 41.2872L14.6143 42.6823"
        stroke={primary}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.0658 21.693C47.4533 21.7642 46.8057 21.8883 46.3986 22.1026C45.4352 22.6105 44.1507 23.879 44.1507 23.879C44.1507 23.879 42.8657 25.3523 40.6816 24.5205L32.3315 21.5954C32.3315 21.5954 30.79 21.0244 30.9827 19.8826C31.1753 18.7407 33.1667 19.1844 33.1667 19.1844L38.0165 20.168"
        stroke={primary}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.3122 20.5167L23.8843 22.3883C23.8843 22.3883 22.2145 22.864 21.6046 21.9371C21.6046 21.9371 20.2232 20.6119 23.0497 19.3434L32.9733 15.4415C32.9733 15.4415 33.7441 14.839 35.4783 15.6318L44.3427 18.5658L47.3939 17.1706"
        stroke={primary}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.1714 28.8271C32.1714 28.8271 32.1714 27.8535 31.0452 27.8535C29.3557 27.7149 29.2147 29.8007 30.9043 29.8007C32.5938 29.6614 32.7348 31.8865 30.9043 31.8865C29.6371 31.8865 29.6371 30.7743 29.6371 30.7743"
        stroke={primary}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9048 27.1583V32.7207"
        stroke={primary}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.2753 29.9396C36.2753 32.8547 33.8826 35.2172 30.9316 35.2172C27.98 35.2172 25.5873 32.8547 25.5873 29.9396C25.5873 27.0245 27.98 24.6621 30.9316 24.6621C33.8826 24.6621 36.2753 27.0245 36.2753 29.9396Z"
        stroke={primary}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SquareAccentCurrencyHands;
