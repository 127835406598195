const PlayIcon = ({
  color = '#FFF',
  backgroundColor = 'none',
  ...props
}: {
  color?: string;
  backgroundColor?: string;
}) => (
  <svg
    width="45"
    height="52"
    viewBox="0 0 45 52"
    fill={backgroundColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.2002 25.9999L0.000195961 51.5188L0.000198192 0.480986L44.2002 25.9999Z"
      fill={color}
    />
  </svg>
);

// --------------------------------------------------------------------------

export default PlayIcon;
