'use client';

import { OPT_OUT_COOKIE_AGE, OPT_OUT_COOKIE_NAME } from './constants';
import { MDSText } from '@midwest/web/base';
import { MDSAnchorButton } from '@midwest/web/button';
import { setCookie } from 'cookies-next';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// --------------------------------------------------------------------------

const LoadOutModal = styled.dialog`
  background-color: ${(p) =>
    p.theme.midwestColors.componentButtonColorBackgroundSubtlePrimaryHover};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  border: none;
  @media (prefers-reduced-motion: no-preference) {
    transition: opacity 0.3s ease-in;
  }
`;

const LoadWrapper = styled.div<{ $constrain: boolean; $visible: boolean }>`
  position: relative;
  height: ${(p) => (p.$constrain ? 'calc(100vh - 6.25rem)' : 'auto')};
  overflow: ${(p) => (p.$visible ? 'hidden' : 'visible')};
  > * {
    position: relative;
    z-index: 1;
  }
  > ${LoadOutModal} {
    position: absolute;
    z-index: 2;
    opacity: ${(p) => (p.$constrain ? '1' : '0')};
  }
`;

const LoadOutModalContainer = styled.div`
  max-width: 56rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;
  padding: 1rem;
`;

const ModalTitle = styled(MDSText.HeroLg)`
  margin-top: 1rem;
`;

const ModalDescription = styled(MDSText.BodyMediumDefault)`
  color: ${(p) => p.theme.midwestColors.textTertiary};
`;

const ModalButton = styled(MDSAnchorButton)`
  margin-top: 1rem;
`;

// --------------------------------------------------------------------------

export const DotcomOptOutModal = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const [showInterstitial, setShowInterstitial] = useState<string | null>(null);
  const [locked, setLocked] = useState(false);
  const contentWrapper = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLAnchorElement | null>(null);
  const modalRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    const dotcomPaths = [
      '/',
      '/giving-back',
      '/working-with-a-financial-advisor',
      '/product-overview',
      '/product-overview/life-insurance',
      '/why-thrivent',
    ];

    const doRedirect = (href: string, setOptOutCookie = false) => {
      if (setOptOutCookie) {
        setCookie(OPT_OUT_COOKIE_NAME, true, { maxAge: OPT_OUT_COOKIE_AGE });
      }
      setShowInterstitial(href);
      setTimeout(() => {
        router.push(href);
        // router.push will do nothing if it's the "same" path
        // i.e. "/"" -> "/"" from the homepage opt out. So we need to follow up here
        // to make sure the page actually reloads
        window.location.href = href;
        router.refresh();
      }, 3000);
    };
    const clickAnchorHandler = (e: Event) => {
      if (!(e.target instanceof Element)) {
        return;
      }
      const el = e.target?.closest('a');
      const href = el?.getAttribute('href') ?? '';
      const origin = window.location.origin;
      const isPath = href[0] === '/';
      const adjustedHref = href.replace(origin, '');
      if (!href || el?.classList.contains('ignore-interstitial')) {
        return;
      }

      // Ignore this is a dotcom page
      if (
        dotcomPaths.includes(adjustedHref) &&
        !el?.classList.contains('is-opt-out-link')
      ) {
        return;
      }

      // Only redirect if we're going to www.thrivent.com proper
      if (
        isPath ||
        href.includes(origin) ||
        href.includes('https://thrivent.com') ||
        href.includes('https://www.thrivent.com') ||
        href.includes('https://www.inttst.thrivent.com')
      ) {
        e.preventDefault();
        e.stopPropagation();
        const setOptOutCookie = el?.classList.contains('is-opt-out-link');
        doRedirect(href, setOptOutCookie);
      }
    };

    // Instead of manually finding every link and putting a wrapper
    // on it, or a new component, we instead will just find them all here
    const allAnchors = document.querySelectorAll('a');
    allAnchors.forEach((anchor) => {
      anchor.addEventListener('click', clickAnchorHandler);
    });

    // Clicking buttons may cause new anchors to appear, (i.e.in modals, tabs)
    // so we need to rebind to them
    const clickButtonHandler = (e: Event) => {
      setTimeout(() => {
        const anchors = document.querySelectorAll('a');
        anchors.forEach((anchor) => {
          anchor.removeEventListener('click', clickAnchorHandler);
        });
        anchors.forEach((anchor) => {
          anchor.addEventListener('click', clickAnchorHandler);
        });
      }, 100);
    };
    const allButtons = document.querySelectorAll('button');
    allButtons.forEach((button) => {
      button.addEventListener('click', clickButtonHandler);
    });

    return () => {
      allAnchors.forEach((anchor) => {
        anchor.removeEventListener('click', clickAnchorHandler);
      });
      allButtons.forEach((button) => {
        button.removeEventListener('click', clickAnchorHandler);
      });
    };
  }, [setShowInterstitial, router]);

  // This locks the page in place for the transition
  // So the modal can "fade in" without the screen snapping to a new position
  useEffect(() => {
    if (showInterstitial && contentWrapper?.current) {
      const footer = document.querySelector('footer');
      const header = document.querySelector('header');
      const footerHeight = footer?.offsetHeight ?? 0;
      const headerHeight = header?.offsetHeight ?? 0;

      const scroll = document.documentElement.scrollTop;
      const height = contentWrapper.current.offsetHeight;
      const pastFooter = scroll > height - footerHeight;

      // If we're past the footer, we have to do a little more work to not have the page jump around
      // We need to figure out our distance from the bottom, lock the page, set our scroll to it, and then
      // gently scroll up to the modal
      if (pastFooter) {
        const scrollDistanceFromBottom = Math.max(
          document.body.offsetHeight - (window.scrollY + window.innerHeight),
          0,
        );
        setLocked(true);
        contentWrapper.current.style.top = `${-(
          contentWrapper.current.offsetHeight -
          footerHeight +
          headerHeight
        )}px`;
        window.scrollTo({
          top: window.innerHeight - scrollDistanceFromBottom,
          left: 0,
          behavior: 'instant',
        });
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        return;
      }
      modalRef?.current?.show();
      buttonRef.current?.focus();

      // If we're in the main content, we can just lock the page and show the modal
      const scrollNew = document.documentElement.scrollTop;
      contentWrapper.current.style.top = `${-scrollNew}px`;
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      });
      setLocked(true);
    }
  }, [showInterstitial, router]);

  return (
    <LoadWrapper $constrain={!!locked} $visible={!!showInterstitial}>
      {!!showInterstitial && (
        <LoadOutModal
          role="dialog"
          data-analytics-name={`dotcom-opt-out-modal`}
          ref={modalRef}
        >
          <LoadOutModalContainer>
            <Image
              src="https://cdn.thrivent.com/89/c5/94677a184d34a3c46495c53203ca/fullbody-highfive.png"
              alt="Drawing of two people giving a high five"
              width="272"
              height="227"
            />
            <ModalTitle as="h2">Redirecting you to our legacy site</ModalTitle>
            <ModalDescription as="p">
              We’re improving our site, but some sections of the site are still
              under construction. Click the button below if you aren’t
              redirected in a few seconds.
            </ModalDescription>
            <ModalButton
              data-analytics-id="dotcom-opt-out-modal-instant-redirect-button"
              className="ignore-interstitial"
              href={showInterstitial}
              ref={buttonRef}
            >
              Reload
            </ModalButton>
          </LoadOutModalContainer>
        </LoadOutModal>
      )}
      <div ref={contentWrapper}>{children}</div>
    </LoadWrapper>
  );
};
