import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import styled, { StyledProps } from 'styled-components';

// --------------------------------------------------------------------------

export interface ChevronRightProps {
  $hideOnMobile?: boolean;
}

// --------------------------------------------------------------------------

export const ChevronRight = styled(ChevronRightIcon)<
  StyledProps<ChevronRightProps>
>`
  &.MuiSvgIcon-root {
    display: ${(p) => (p.$hideOnMobile ? 'none' : 'inline-block')};
  }
  @media screen and (min-width: 400px) {
    &.MuiSvgIcon-root {
      display: inline-block;
    }
    margin-left: 0.25rem;
  }
`;
