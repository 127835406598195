'use client';

import Image, { ImageProps } from 'next/image';
import { useState } from 'react';

const DEFAULT_IMAGE_SRC =
  'https://cdn.thrivent.com/7e/b7/3b5427e24ae88e93302b6a686044/default.jpg';

const DefaultImage = ({ src, alt, ...rest }: ImageProps) => {
  const [hasError, setHasError] = useState(false);

  return (
    <Image
      quality={100}
      width={714}
      height={486}
      src={!hasError ? src : DEFAULT_IMAGE_SRC}
      alt={!hasError ? alt : 'default image'}
      onError={() => setHasError(true)}
      {...rest}
    />
  );
};

export default DefaultImage;
