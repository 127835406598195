import * as animationData from './data.json';
import Lottie from 'lottie-react';

export const Spinner = ({
  size = 70,
  className,
}: {
  className?: string;
  size?: number;
}) => {
  return (
    <div className={className}>
      <Lottie
        title="spinner"
        aria-label="loading"
        role="alert"
        loop={true}
        animationData={animationData}
        height={size}
        width={size}
      />
    </div>
  );
};
