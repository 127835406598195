import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const SquareAccentShieldCheck = ({
  height = 50,
  width = 50,
  colorPrimary,
  colorSecondary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryMedium;
  const secondary = colorSecondary ?? midwestColors.identityBrandPrimaryFaint;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.01471 9.05883C5.01471 8.06796 5.81796 7.26471 6.80883 7.26471H32.1029C33.0938 7.26471 33.8971 8.06796 33.8971 9.05883V12.8529H37.8971V9.05883C37.8971 5.85882 35.3029 3.26471 32.1029 3.26471H6.80883C3.60882 3.26471 1.01471 5.85882 1.01471 9.05883V34.3529C1.01471 37.5529 3.60882 40.1471 6.80883 40.1471H10.6029V36.1471H6.80883C5.81796 36.1471 5.01471 35.3438 5.01471 34.3529V9.05883Z"
        fill={secondary}
      />
      <path
        d="M16.0591 21.2014C14.7506 21.6184 13.9468 21.7522 13.9468 21.7522C13.9468 21.7522 12.6517 34.7078 16.9697 39.8896C20.1218 43.6724 26.0389 46.3677 26.0389 46.3677H26.7202C26.7202 46.3677 32.6367 43.6724 35.7889 39.8896C40.1074 34.7078 38.8118 21.7522 38.8118 21.7522C38.8118 21.7522 36.2205 21.3205 32.766 19.5933C29.466 17.943 27.2687 16.5012 26.7202 16.1381C26.3795 15.8605 26.0389 16.1381 26.0389 16.1381C26.0389 16.1381 25.2272 16.6715 23.9564 17.4236C23.9564 17.4236 21.6474 18.7777 19.892 19.6585"
        stroke={primary}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5179 30.3885L24.6481 35.4557L33.4323 26.6714"
        stroke={primary}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SquareAccentShieldCheck;
