import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const PersonInComputer = ({
  height = 65,
  width = 65,
  colorPrimary,
  colorSecondary,
  colorTertiary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryHeavy;
  const secondary = colorSecondary ?? midwestColors.identityBrandTertiary;
  const tertiary = colorTertiary ?? midwestColors.white;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 65"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M61.7638 6.42407H2.62942C1.95544 6.42407 1.40912 6.98564 1.40912 7.67836V47.3305C1.40912 48.0232 1.95544 48.5848 2.62942 48.5848H61.7638C62.4378 48.5848 62.9841 48.0232 62.9841 47.3305V7.67836C62.9841 6.98564 62.4378 6.42407 61.7638 6.42407Z"
        stroke={primary}
        fill="none"
        className="stroke-primary"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M58.9358 41.702H5.46107C4.90404 41.702 4.44911 41.2387 4.44911 40.6618V10.6245C4.44911 10.0519 4.89979 9.58432 5.46107 9.58432H58.9358C59.4928 9.58432 59.9479 10.0476 59.9479 10.6245V40.6618C59.9479 41.2344 59.4971 41.702 58.9358 41.702Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M58.9358 9.58432H5.46107C4.90215 9.58432 4.44911 10.05 4.44911 10.6245V40.6618C4.44911 41.2363 4.90215 41.702 5.46107 41.702H58.9358C59.4947 41.702 59.9479 41.2363 59.9479 40.6618V10.6245C59.9479 10.05 59.4947 9.58432 58.9358 9.58432Z"
        stroke={secondary}
        fill="none"
        className="stroke-secondary"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M35.5364 48.5847H29.0136V56.9408H35.5364V48.5847Z"
        stroke={primary}
        className="stroke-primary"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M42.6591 56.941H21.8917V59.5763H42.6591V56.941Z"
        stroke={primary}
        fill="none"
        className="stroke-primary"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M54.251 29.6785C54.5657 29.2939 54.5148 28.717 54.1406 28.3936L50.7473 25.4655L52.8011 23.5644C52.9712 23.407 52.9074 23.1186 52.6863 23.0574L44.0544 20.5313C43.8121 20.4614 43.6038 20.7149 43.7144 20.9509L47.6305 29.2502C47.7283 29.46 48.0175 29.4774 48.1365 29.2764L49.6035 26.8683L52.9967 29.7965C53.3709 30.1199 53.9322 30.0674 54.2469 29.6828"
        stroke={tertiary}
        fill="none"
        className="stroke-tertiary"
      />
      <path
        d="M24.4855 37.3616V32.2701C24.4855 28.398 28.1423 25.2557 32.0713 25.2557C36.0003 25.2557 39.6571 28.3936 39.6571 32.2701V37.3616"
        stroke={tertiary}
        fill="none"
        className="stroke-tertiary"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M36.63 18.7397C36.63 21.2963 34.6145 23.3679 32.127 23.3679C29.6395 23.3679 27.624 21.2963 27.624 18.7397C27.624 16.183 29.6395 14.1115 32.127 14.1115C34.6145 14.1115 36.63 16.183 36.63 18.7397Z"
        stroke={tertiary}
        fill="none"
        className="stroke-tertiary"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M21.4397 45.0451H43.1085"
        stroke={primary}
        fill="none"
        className="stroke-primary"
        strokeWidth="1"
        strokeMiterlimit="10"
        stroke-linecap="square"
      />
    </svg>
  );
};

export default PersonInComputer;
