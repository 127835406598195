import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const AlarmClock = ({
  height = 64,
  width = 64,
  colorPrimary,
  colorSecondary,
  colorTertiary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryHeavy;
  const secondary = colorSecondary ?? midwestColors.identityBrandPrimaryWeak;
  const tertiary = colorTertiary ?? midwestColors.white;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Group">
        <path
          id="Vector"
          d="M32 0C14.3267 0 0 14.3267 0 32C0 49.6733 14.3267 64 32 64C49.6733 64 64 49.6733 64 32C64 14.3267 49.6729 0 32 0ZM14.5197 15.9678C15.5748 13.5697 18.0544 11.8666 20.6713 11.7424C23.2882 11.6181 26.1647 13.1056 27.4422 15.393L15.6745 23.4192C13.9976 21.4061 13.4649 18.3656 14.5197 15.9678ZM40.8908 46.6459L43.1016 52.9007C43.1294 52.9798 43.0262 53.0373 42.9734 52.9726L38.7729 47.7999C36.7181 48.7297 34.4375 49.2469 32.0357 49.2469C29.6006 49.2469 27.2898 48.7146 25.2128 47.7606L20.9805 52.9726C20.9277 53.0377 20.8245 52.9798 20.8523 52.9007L23.085 46.584C18.6221 43.6622 15.6737 38.6181 15.6737 32.8853C15.6737 23.9726 22.7999 16.7249 31.6649 16.528V12.6475H30.0286V11.4974H33.9361V12.6475H32.2997V16.526C41.214 16.6673 48.3966 23.9377 48.3966 32.8857C48.3978 38.6586 45.4073 43.7336 40.8908 46.6459ZM48.2803 23.4192L36.5125 15.393C37.79 13.1056 40.6665 11.6181 43.2834 11.7424C45.9004 11.8666 48.3799 13.5697 49.4351 15.9678C50.4899 18.3656 49.9567 21.4061 48.2803 23.4192Z"
          fill={tertiary}
        />
        <path
          id="Vector_2"
          d="M32.3008 12.0719V12.6471H33.9372V11.4971H30.0293V12.6471H31.6657V12.0719H32.3008Z"
          fill={primary}
        />
        <path
          id="Vector_3"
          d="M32.0362 16.5234C32.1247 16.5234 32.2128 16.5242 32.3009 16.5258V12.6472V12.072H31.6658V12.6472V16.5278C31.7714 16.5254 31.8774 16.5242 31.9834 16.5238C32.0008 16.5234 32.0187 16.5234 32.0362 16.5234Z"
          fill={primary}
        />
        <path
          id="Vector_4"
          d="M42.9738 52.9722C43.0266 53.0373 43.1298 52.9794 43.102 52.9004L40.8912 46.6455C40.2175 47.0798 39.5101 47.4661 38.7737 47.7995L42.9738 52.9722Z"
          fill={primary}
        />
        <path
          id="Vector_5"
          d="M20.8531 52.9008C20.8253 52.9798 20.9285 53.0373 20.9813 52.9726L25.2135 47.7606C24.4727 47.4204 23.7621 47.0266 23.0861 46.584L20.8531 52.9008Z"
          fill={primary}
        />
        <path
          id="Vector_6"
          d="M32.301 16.5256C32.2129 16.524 32.1247 16.5232 32.0362 16.5232C32.0187 16.5232 32.0009 16.5236 31.9834 16.5236C31.8774 16.524 31.7714 16.5252 31.6658 16.5276C22.8004 16.7245 15.6746 23.9726 15.6746 32.8848C15.6746 38.6177 18.623 43.6617 23.0858 46.5835C23.7619 47.0262 24.4729 47.42 25.2133 47.7602C27.2899 48.7141 29.6007 49.2465 32.0362 49.2465C34.438 49.2465 36.7186 48.7288 38.7734 47.7995C39.5102 47.4664 40.2172 47.0798 40.8909 46.6455C45.4074 43.7332 48.3979 38.6582 48.3979 32.8844C48.3979 23.9369 41.2153 16.6669 32.301 16.5256ZM32.7059 33.1345C32.2819 33.518 31.621 33.4573 31.2736 33.0035L23.6547 23.2417C23.6031 23.1758 23.694 23.0937 23.7544 23.1512L32.114 31.1199L38.7702 26.073C39.07 25.8455 39.434 26.2588 39.1708 26.5276L32.8377 32.9889C32.7992 33.0405 32.7555 33.0897 32.7059 33.1345Z"
          fill={secondary}
        />
        <path
          id="Vector_7"
          d="M27.4422 15.393C26.1647 13.1056 23.2882 11.6181 20.6713 11.7423C18.0544 11.8666 15.5748 13.5697 14.5196 15.9678C13.4644 18.366 13.9976 21.4061 15.6741 23.4192L27.4422 15.393Z"
          fill={primary}
        />
        <path
          id="Vector_8"
          d="M43.2834 11.7423C40.6665 11.6181 37.7899 13.1056 36.5125 15.393L48.2802 23.4192C49.9567 21.4061 50.4898 18.3656 49.4347 15.9678C48.3799 13.5697 45.9003 11.8666 43.2834 11.7423Z"
          fill={primary}
        />
        <path
          id="Vector_9"
          d="M38.7709 26.0731L32.1143 31.1199L23.7546 23.1513C23.6939 23.0937 23.6033 23.1759 23.655 23.2418L31.2738 33.0036C31.6212 33.4574 32.2822 33.5181 32.7062 33.1346C32.7558 33.0898 32.7995 33.0409 32.838 32.9889L39.1718 26.5276C39.4346 26.2589 39.0706 25.8456 38.7709 26.0731Z"
          fill={primary}
        />
      </g>
    </svg>
  );
};

export default AlarmClock;
