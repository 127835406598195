'use client';

import {
  OPT_OUT_BANNER_DISMISSAL_COOKIE_AGE,
  OPT_OUT_BANNER_DISMISSAL_COOKIE_NAME,
} from './constants';
import { Container as ExplorationContainer } from '@exploration/shared-components';
import { MDSInlineMessage } from '@midwest/web/notifications-v2';
import { setCookie } from 'cookies-next';
import NextLink from 'next/link';
import { useState } from 'react';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const BannerContainer = styled(ExplorationContainer)`
  margin: 1rem auto;
`;

export const Link = styled(NextLink)`
  color: ${(p) => p.theme.midwestColors.textLink};
  text-decoration: underline;
`;

export const TextContainer = styled.span`
  color: ${(p) => p.theme.midwestColors.black};
`;

// --------------------------------------------------------------------------

export const DotcomOptOutBanner = ({}) => {
  const [dismissed, setDismissed] = useState(false);

  const clickHandler = () => {
    setCookie(OPT_OUT_BANNER_DISMISSAL_COOKIE_NAME, true, {
      maxAge: OPT_OUT_BANNER_DISMISSAL_COOKIE_AGE,
    });
    setDismissed(true);
  };

  return (
    <div>
      {!dismissed && (
        <BannerContainer>
          <MDSInlineMessage
            type="info"
            subtle
            closeButton
            closeOnClick={clickHandler}
          >
            <TextContainer>
              We are in the process of bringing you a new Thrivent.com
              experience. Help us out by exploring below, or{' '}
              <Link
                data-analytics-id="dotcom-opt-out-banner-cta"
                className={'is-opt-out-link'}
                href="/"
              >
                return to the classic Thrivent.com
              </Link>
            </TextContainer>
          </MDSInlineMessage>
        </BannerContainer>
      )}
    </div>
  );
};
