'use client';

import { SectionContainer, SectionRoot } from '@exploration/shared-components';
import { desktop } from '@thrivent/midwest-shared';
import { MDSText } from '@thrivent/midwest-web';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const Section = styled(SectionRoot)<{ backgroundColor?: string }>`
  background-color: ${(props) => props.backgroundColor};
  padding-top: 1.5rem;
  padding-bottom: 2.5rem;
`;

export const Container = styled(SectionContainer)`
  min-height: fit-content;
  ${desktop} {
    flex-direction: column;
  }
`;

export const DisclaimerText = styled(MDSText.BodyRegXs)`
  width: 100%;
  max-width: 50rem;
  display: block;
  margin-top: 0.25rem;
  color: ${(p) => p.theme.midwestColors.neutralDarkMedium};

  a {
    font-size: 1em;
    text-decoration: underline;
  }
`;
