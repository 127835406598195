import { MDSNotificationLink } from './styles';
import { NotificationLinkProps, NotificationTypes } from './types';
import {
  Children,
  ReactElement,
  ReactNode,
  cloneElement,
  isValidElement,
} from 'react';

const isSystemAlertLink = (
  element: ReactElement,
): element is ReactElement<NotificationLinkProps> => {
  return element.type === MDSNotificationLink;
};

export const styleChildLinks = (
  children: ReactNode,
  $darkBg: boolean,
  type: NotificationTypes,
  subtle: boolean,
): ReactNode => {
  return Children.map(children, (child) => {
    if (typeof child === 'string' && child.trim() === '') {
      return <>&nbsp;</>; // Preserve whitespace using non-breaking space
    }
    if (
      isValidElement<NotificationLinkProps>(child) &&
      isSystemAlertLink(child)
    ) {
      return cloneElement(child, {
        $darkBg: $darkBg,
        systemAlertType: type,
        subtle: subtle,
      });
    }
    if (
      isValidElement<{ children?: ReactNode }>(child) &&
      child.props.children
    ) {
      return cloneElement(child, {
        children: styleChildLinks(child.props.children, $darkBg, type, subtle),
      });
    }
    return child;
  });
};
