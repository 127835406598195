'use client';

import { BaseLink } from '../BaseLink';
import { StyledLogo } from '../Logo';
import { StyledButton } from './HeaderLoginButton';
import { MDSAnchorButton } from '@midwest/web/button';
import { breakpoints } from '@thrivent/midwest-shared';
import styled, { css } from 'styled-components';

export const Header = styled.div`
  padding: 0.75rem 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${breakpoints.tablet} {
    padding: 1rem 2rem;
  }

  ${breakpoints.desktop} {
    align-items: flex-start;
    flex-direction: column;
  }

  @media screen and (min-width: 1280px) {
    flex-direction: row;
    justify-content: flex-start;
    padding: 2rem 3rem;
  }

  @media screen and (min-width: 1280px) and (max-width: 1375px) {
    flex-direction: row;
    justify-content: flex-start;
    padding: 2rem 1rem;
  }
  @media screen and (min-width: 1024px) and (max-width: 1100px) {
    padding: 2rem 1rem;
  }
`;

export const NavigationContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  ${breakpoints.desktop} {
    width: 100%;
    justify-content: space-between;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${breakpoints.desktop} {
    padding-right: 1rem;
  }

  ${StyledLogo} {
    width: 84px;

    ${breakpoints.desktop} {
      width: auto;
    }
  }
`;

export const HeaderNav = styled.div`
  display: none;

  ${breakpoints.desktop} {
    flex: 1;
    display: flex;
    gap: 2rem;
    padding-right: 1rem;
  }

  @media screen and (min-width: 1280px) {
    justify-content: center;
    gap: 3rem;
  }
  @media screen and (min-width: 1280px) and (max-width: 1375px) {
    gap: 1.25rem;
  }
  @media screen and (min-width: 1024px) and (max-width: 1100px) {
    gap: 1.25rem;
  }
`;

export const NavButton = styled(BaseLink)`
  background: transparent;
  border-bottom: solid transparent 2px;
  text-wrap: nowrap;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.midwestColors.componentButtonColorTextSubtlePrimary};
  font-family: ${(p) => p.theme.font.primary};
  font-weight: 400;

  &:hover,
  &:focus {
    text-decoration: none;
    border-bottom-color: ${(p) => p.theme.midwestColors.borderDefault};
  }

  &.active {
    border-bottom-color: transparent;
    color: ${(p) =>
      p.theme.midwestColors.componentButtonColorBorderSubtlePrimaryPressed};
    font-weight: bold;
  }
`;

export const HeaderButtons = styled.div`
  ${breakpoints.desktop} {
    display: none;
  }
`;

export const HeaderButtonsDesktop = styled.div`
  display: none;
  text-wrap: nowrap;

  ${breakpoints.desktop} {
    display: flex;
    gap: 1rem;
  }
`;

export const FindAnFAButton = styled(MDSAnchorButton)``;

const brandRefreshOverrides = css`
  border-bottom: none;

  ${NavButton} {
    color: ${(p) => p.theme.midwestColors.black};
    position: relative;
    flex-wrap: wrap;
    height: 3.25rem;
    padding: 0.875rem 0;
    display: block;
    text-align: center;

    &::after {
      position: relative;
      content: attr(title);
      display: block;
      height: 1px;
      text-wrap: nowrap;
      font-weight: 700;
      z-index: 2;
      color: transparent;
      visibility: hidden;
    }

    &.active {
      color: ${(p) => p.theme.midwestColors.sentimentNegativeStrong};
    }
  }

  ${StyledButton} {
    color: ${(p) => p.theme.midwestColors.textSecondary};
    border: 2px solid ${(p) => p.theme.midwestColors.textSecondary};
    font-weight: 500;
    font-family: ${(p) => p.theme.font.primary};

    &:hover,
    &:focus {
      background-color: ${(p) =>
        p.theme.midwestColors.identityBrandPrimaryWeak};
      border-color: ${(p) =>
        p.theme.midwestColors.componentButtonColorBackgroundStrongPrimaryHover};
    }
  }

  ${FindAnFAButton} {
    display: flex;
    font-weight: 500;
    font-family: ${(p) => p.theme.font.primary};
  }
`;

export const Container = styled.div<{ brandRefresh?: boolean }>`
  position: sticky;
  top: 0;
  z-index: 598;

  border-bottom: 1px solid
    ${(p) => p.theme.midwestColors.semanticColorSentimentInfoWeak};
  background: ${(p) => p.theme.midwestColors.white};

  ${(p) => (p.brandRefresh ? brandRefreshOverrides : '')}
`;
