import {
  ButtonProps,
  LinkProps,
  NotificationLinkProps,
  NotificationTypes,
} from '../shared/types';
import {
  AlertIcon,
  CloseFilled,
  CloseIcon,
  InfoIcon,
  ValidCircle,
  toKebabCase,
} from '@midwest/web/base';
import { MDSButton } from '@midwest/web/button';
import { MDSLink } from '@midwest/web/link';
import { MidwestColors } from '@thrivent/midwest-shared';
import styled from 'styled-components';

export const getFontColors = (colors: MidwestColors) => ({
  info: colors.componentContainerColorTextHeadingInfoEmphasis,
  positive: colors.componentContainerColorTextHeadingPositiveEmphasis,
  negative: colors.componentContainerColorTextHeadingNegativeEmphasis,
  warning: colors.componentContainerColorTextHeadingWarningEmphasis,
});

const getSubtleIconColors = (colors: MidwestColors) => ({
  info: colors.componentContainerColorBorderInfo,
  positive: colors.componentContainerColorBorderPositive,
  negative: colors.componentContainerColorBorderNegative,
  warning: colors.componentContainerColorTextHeadingSubtle,
});

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.xxsm};
`;

const ButtonOrLinkContainer = styled.div`
  margin-top: ${(p) => p.theme.spacing.xxsm};
`;

export const ButtonOrLink = ({
  buttonOrLink,
  darkBg,
  type,
  subtle = false,
}: {
  buttonOrLink: ButtonProps | LinkProps;
  darkBg: boolean;
  type: NotificationTypes;
  subtle?: boolean;
}) => {
  return (
    <ButtonOrLinkContainer>
      {buttonOrLink && 'href' in buttonOrLink && (
        <MDSNotificationLink
          {...buttonOrLink}
          darkBg={darkBg}
          href={buttonOrLink.href}
          systemAlertType={type}
          subtle={subtle}
          type={buttonOrLink.icon}
          data-analytics-id={
            buttonOrLink?.id ? toKebabCase(buttonOrLink.id) : null
          }
        >
          {buttonOrLink.label}
        </MDSNotificationLink>
      )}
      {buttonOrLink && 'onClick' in buttonOrLink && (
        <MDSButton
          onClick={buttonOrLink.onClick}
          variant={
            type === 'warning'
              ? 'secondary'
              : type === 'negative'
                ? 'destructive'
                : 'primary'
          }
          size="small"
          weight="subtle"
          $darkBg={darkBg}
          data-analytics-id={
            buttonOrLink?.id ? toKebabCase(buttonOrLink.id) : null
          }
        >
          {buttonOrLink.label}
        </MDSButton>
      )}
    </ButtonOrLinkContainer>
  );
};

export const IconWrapper = styled.div<{
  $type: NotificationTypes;
  $subtle?: boolean;
}>`
  height: 24px;
  width: 24px;
  margin-right: 4px;
  path {
    fill: ${({ $type, $subtle, theme }) =>
      $subtle
        ? getSubtleIconColors(theme.midwestColors as MidwestColors)[$type]
        : getFontColors(theme.midwestColors as MidwestColors)[$type]};
  }
`;

export const CloseButtonIcon = styled(
  ({
    $type,
    $subtle,
    ...props
  }: {
    $type: NotificationTypes;
    $subtle: boolean;
  }) => <CloseIcon {...props} />,
)`
  height: 24px;
  width: 24px;
  fill: ${(p) =>
    p.$subtle
      ? p.theme.midwestColors.textSecondary
      : getFontColors(p.theme.midwestColors as MidwestColors)[p.$type]};
`;

export const CloseButton = styled.button<{ darkBg: boolean }>`
  display: flex;
  align-items: center;
  margin: auto;
  margin-right: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-top: 0;
  outline: none;

  &:focus-visible {
    outline: ${(p) => p.theme.border.widthFocused} solid
      ${(p) =>
        p.darkBg
          ? p.theme.midwestColors.borderFocusDarkBg
          : p.theme.midwestColors.borderFocus};
    border-radius: ${(p) => p.theme.radius.radiusMedium};
  }
`;

const getLinkFontColor = (
  subtle: boolean,
  midwestColors: MidwestColors,
  type?: NotificationTypes,
) => {
  return subtle
    ? midwestColors.componentLinkDefault
    : getFontColors(midwestColors)[type || ('info' as NotificationTypes)];
};

export const MDSNotificationLink = styled(MDSLink)<NotificationLinkProps>`
  color: ${({ systemAlertType, subtle = false, theme }) => {
    return `${getLinkFontColor(subtle, theme.midwestColors as MidwestColors, systemAlertType)} !important`;
  }};
  text-decoration: underline;
  path {
    fill: ${({ systemAlertType, subtle = false, theme }) => {
      return `${getLinkFontColor(subtle, theme.midwestColors as MidwestColors, systemAlertType)} !important`;
    }};
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 ${(p) => p.theme.border.widthFocused}
      ${(p) =>
        p.$darkBg
          ? p.theme.midwestColors.borderFocusDarkBg
          : p.theme.midwestColors.borderFocus};
    border-radius: 5px;
  }
`;

export const typeMappings = {
  info: {
    role: 'status',
    label: 'information',
    inlineMessageLabel: 'General',
    icon: InfoIcon,
  },
  positive: {
    role: 'status',
    label: 'notification',
    inlineMessageLabel: 'Success',
    icon: ValidCircle,
  },
  negative: {
    role: 'alert',
    label: 'alert',
    inlineMessageLabel: 'Alert',
    icon: CloseFilled,
  },
  warning: {
    role: 'alert',
    label: 'warning',
    inlineMessageLabel: 'Warning',
    icon: AlertIcon,
  },
};
