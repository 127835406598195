import { Spinner } from '@thrivent-web/ui/icons';
import LoadingOverlay from 'react-loading-overlay';
import styled from 'styled-components';

interface LoadingOverlayStyledProps {
  position?: 'fixed' | 'absolute' | 'relative' | 'static' | 'sticky';
}

const LoadingOverlayStyled = styled(LoadingOverlay)<LoadingOverlayStyledProps>`
  &._loading_overlay_wrapper {
    position: inherit;
    > ._loading_overlay_overlay {
      position: ${(props) => props.position || 'absolute'};
    }
  }
`;

export type Props = {
  active: boolean;
  size?: number;
  position?: 'fixed' | 'absolute' | 'relative' | 'static' | 'sticky';
};

export const PageLoader = ({ active, size = 200, position }: Props) => {
  return (
    <LoadingOverlayStyled
      active={active}
      spinner={<Spinner size={size} />}
      fadeSpeed={1000}
      position={position}
    ></LoadingOverlayStyled>
  );
};
