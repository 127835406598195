import {
  AlertIcon,
  CloseFilled,
  CloseIcon,
  InfoIcon,
  ValidCircle,
} from '@midwest/web/base';
import { MDSButton } from '@midwest/web/button';
import { MDSLink } from '@midwest/web/link';
import { border, colors, radius } from '@thrivent/midwest-shared';
import styled from 'styled-components';

export type SystemAlertType = {
  $type?: string;
  $sticky?: boolean;
  $zIndex?: number;
};

export const SystemAlertRoot = styled.div<SystemAlertType>`
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 12px 24px;
  gap: 16px;
  box-shadow: 0px 3px 8px 0px rgba(21, 21, 21, 0.15);
  background: ${(p) =>
    p.$type === 'informative'
      ? colors.identityBrandPrimaryMedium
      : p.$type === 'positive'
        ? colors.sentimentSuccessMedium
        : p.$type === 'negative'
          ? colors.sentimentNegativeStrong
          : colors.sentimentWarningMedium};

  position: ${(p) => (p.$sticky ? 'sticky' : '')};
  top: 0;
  left: 0;
  z-index: ${(p) => p.$zIndex};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

export const IconWrapper = styled.div<{ $color: string }>`
  height: 24px;
  width: 24px;

  margin-top: 2px;

  path {
    fill: ${(p) => p.$color};
  }
`;

export const InlineLink = styled(MDSLink)<SystemAlertType>`
  color: ${(p) =>
    p.$type === 'informative' || p.$type === 'negative'
      ? `${colors.backgroundPrimary} !important`
      : `${colors.neutralDarkStrong} !important`};
  text-decoration: underline;
`;

export const StandaloneLink = styled(MDSLink)<SystemAlertType>`
  color: ${(p) =>
    p.$type === 'informative' || p.$type === 'negative'
      ? `${colors.backgroundPrimary} !important`
      : `${colors.neutralDarkStrong} !important`};
  text-decoration: underline;
  margin-top: 12px;
`;

export const Button = styled(MDSButton)`
  margin-top: 12px;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  margin: auto;
  margin-right: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-top: 0;
  outline: none;

  &:focus-visible {
    outline: ${border.widthFocused} solid ${colors.borderFocus};
    border-radius: ${radius.radiusMedium};
  }
`;

export const CloseButtonIcon = styled((props) => <CloseIcon {...props} />)`
  height: 24px;
  width: 24px;
  path {
    fill: ${(p) =>
      p.$type === 'informative' || p.$type === 'negative'
        ? `${colors.backgroundPrimary}`
        : `${colors.neutralDarkStrong}`};
  }
`;

export const getIconAndColor = (variant: string) => {
  let Icon = CloseFilled;
  let color = colors.neutralDarkStrong;

  switch (variant) {
    case 'warning':
      Icon = AlertIcon;
      break;
    case 'positive':
      Icon = ValidCircle;
      break;
    case 'informative':
      Icon = InfoIcon;
      color = colors.backgroundPrimary;
      break;
    case 'negative':
      Icon = CloseFilled;
      color = colors.backgroundPrimary;
      break;
  }

  return { Icon, color };
};
