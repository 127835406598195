'use client';

import {
  Header,
  HeaderFooterFlagType,
  headerFooterFeatureFlagAtom,
} from '@thrivent/thrivent-public-header-footer';
import { Provider } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';

function HydratedHeader({ flags }: { flags: HeaderFooterFlagType }) {
  useHydrateAtoms([[headerFooterFeatureFlagAtom, flags]]);
  return <Header />;
}

export const FlaggedHeader = ({ flags }: { flags: HeaderFooterFlagType }) => (
  <Provider>
    <HydratedHeader flags={flags} />
  </Provider>
);
