import { ChevronRight } from '../button-icons';
import ButtonLinkVideo from '../video-deprecated/button-link-video';
import { IconCardStyles } from './icon-card.style';
import { Icon, svgIcons } from '@exploration/icons';
import { parseSanitizeItem, useForceBreakpoint } from '@exploration/ui-utils';

// --------------------------------------------------------------------------

const {
  Container,
  Content,
  ContentContainer,
  IconContainer,
  Subtitle,
  Description,
  Button,
  Link,
} = IconCardStyles;

// --------------------------------------------------------------------------

export interface IconCardProps {
  icon?: svgIcons;
  headline?: string | React.ReactNode;
  subHeadline?: string | React.ReactNode;
  buttonType?: 'button' | 'link' | 'video';
  link?: string;
  text?: string;
  video?: {
    videoId?: string;
    title?: string;
  };
  buttonId?: string;
  ariaId?: string;
  buttonComponent?: string | React.ReactNode;
  className?: string;
  key?: string;
  showChevron?: boolean;
  trackingId?: string;
  showDarkerBlueButton?: boolean;
}

// --------------------------------------------------------------------------

const IconCard = ({
  icon,
  headline,
  subHeadline,
  buttonType = 'button',
  link,
  text,
  video,
  buttonId,
  ariaId,
  buttonComponent,
  className,
  showChevron = true,
  trackingId,
  showDarkerBlueButton,
}: IconCardProps) => {
  Button.defaultProps = {
    forceMobileBreakpoint: useForceBreakpoint() === 'mobile',
    darkBlueColor: showDarkerBlueButton,
  };

  let button = null;
  if (buttonComponent) {
    button = parseSanitizeItem(buttonComponent);
  } else if (!text || !link) {
    button = null;
  } else if (buttonType === 'button') {
    button = (
      <Button
        id={buttonId}
        href={link ?? '#'}
        aria-label={ariaId}
        data-analytics-id={trackingId}
      >
        {text} {showChevron && <ChevronRight />}
      </Button>
    );
  } else if (buttonType === 'link') {
    button = (
      <Link
        id={buttonId}
        href={link ?? '#'}
        type="navigational"
        aria-label={ariaId}
        data-analytics-id={trackingId}
      >
        {text}
      </Link>
    );
  }
  return (
    <Container className={className}>
      <Content>
        <IconContainer>
          <Icon icon={icon} />
        </IconContainer>
        <ContentContainer>
          <Subtitle as={`${className === 'h2-subtitle' ? 'h2' : 'h3'}`}>
            {parseSanitizeItem(headline)}
          </Subtitle>
          {!!subHeadline && (
            <Description>{parseSanitizeItem(subHeadline)}</Description>
          )}
          {buttonType === 'video' && video && (
            <ButtonLinkVideo
              videoId={video.videoId || ''}
              titleText={video.title}
            />
          )}
        </ContentContainer>
      </Content>
      {button}
    </Container>
  );
};

export default IconCard;
