import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const SquareAccentCurrencyCircle = ({
  height = 50,
  width = 50,
  colorPrimary,
  colorSecondary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryMedium;
  const secondary = colorSecondary ?? midwestColors.identityBrandPrimaryFaint;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.0147 9.05883C5.0147 8.06796 5.81796 7.26471 6.80882 7.26471H32.1029C33.0938 7.26471 33.8971 8.06796 33.8971 9.05883V12.8529H37.8971V9.05883C37.8971 5.85882 35.3029 3.26471 32.1029 3.26471H6.80882C3.60882 3.26471 1.0147 5.85882 1.0147 9.05883V34.3529C1.0147 37.5529 3.60882 40.1471 6.80882 40.1471H10.6029V36.1471H6.80882C5.81796 36.1471 5.0147 35.3438 5.0147 34.3529V9.05883Z"
        fill={secondary}
      />
      <path
        d="M17.3539 24.8343C19.5861 19.5563 24.8124 15.8525 30.9035 15.8525C38.0092 15.8525 43.938 20.8936 45.3097 27.5948"
        stroke={primary}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.0742 23.7346L45.7731 28.3314L41.1766 26.0302"
        stroke={primary}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.9552 36.2836C42.7231 41.5616 37.4967 45.2653 31.4057 45.2653C24.3 45.2653 18.3712 40.2242 16.9995 33.523"
        stroke={primary}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2347 37.3833L16.5358 32.7865L21.1323 35.0877"
        stroke={primary}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.354 27.6531C34.354 27.6531 34.3546 25.1106 31.4482 25.1106C27.0904 24.7471 26.7276 30.1956 31.0854 30.1956C35.4438 29.8321 35.8072 35.6435 31.0854 35.6435C27.8174 35.6435 27.8167 32.7381 27.8167 32.7381"
        stroke={primary}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.0865 23.2947V37.8233"
        stroke={primary}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SquareAccentCurrencyCircle;
