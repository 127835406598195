import { Container, DisclaimerText, Section } from './disclaimer-section.style';
import { sanitize } from 'isomorphic-dompurify';
import ReactHtmlParser from 'react-html-parser';

// --------------------------------------------------------------------------

export const DisclaimerSectionStyles = { Section, Container, DisclaimerText };

// --------------------------------------------------------------------------

const defaultDisclaimers = [
  'By selecting “Find a Financial Advisor” you will have the opportunity to find and request contact with a Thrivent financial advisor near you. <br> By selecting “Contact the Virtual Advice Team” you will be able to schedule a call with a financial advisor from the Virtual Advice Team.',
];

// --------------------------------------------------------------------------

export interface DisclaimerSectionProps {
  disclaimers?: string[];
  backgroundColor?: string;
  className?: string;
}

// --------------------------------------------------------------------------

export const DisclaimerSection = ({
  disclaimers = defaultDisclaimers,
  backgroundColor,
  className,
}: DisclaimerSectionProps) => {
  return (
    <Section className={className} backgroundColor={backgroundColor}>
      <Container>
        {disclaimers.map((disc) => (
          <DisclaimerText as="small" key={disc.substring(0, 10)}>
            {ReactHtmlParser(sanitize(disc, { ADD_ATTR: ['target'] }))}
          </DisclaimerText>
        ))}
      </Container>
    </Section>
  );
};
